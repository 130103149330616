import React, {FC} from 'react';
import PageLayout from "../components/layout/page-layout";

interface IFDAUpdateProps {

}

const FDAUpdate: FC<IFDAUpdateProps> = ({}: IFDAUpdateProps) => {

  return (
    <PageLayout>
      <h1>FDAUpdate</h1>
    </PageLayout>
  );
}

export default FDAUpdate;
